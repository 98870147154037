import { People } from '@styled-icons/evaicons-solid';
import { Icon, IconProps } from './Icon';

export function PeopleFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <People />
    </Icon>
  );
}
